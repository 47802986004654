import React, { Component } from 'react';

class AlertSuccessStudy extends Component {
    clickTranslate = () => {
        const translateclick = document.querySelector('i.fa-solid.fa-language');
        const example_mean_show = document.querySelector('.example_mean');
        translateclick.onclick = function (){
            example_mean_show.classList.toggle('show');
        }
    }
    render() {
        return (
            <div className="alerts alertSuccess">
                <h2>Đáp án đúng</h2>
                <div className="alert">
                    <div className="contentAlert">
                        <p>{this.props.name + ' : ' + this.props.mean}</p>
                        <div className ="meanExampleAll">
                            <i className ="fa-solid fa-language" onClick={this.clickTranslate}></i>
                            <p>{this.props.example}</p>
                        </div>
                        <p className="example_mean">{this.props.mean_example}</p>
                    </div>
                    <button
                        name=""
                        id=""
                        className="btn btn-primary continue"
                        onClick={this.props.howToLearnNext}
                    >
                        Tiếp tục
                    </button>
                </div>
            </div>
        );
    }
}

export default AlertSuccessStudy;