import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChatBot from 'react-simple-chatbot';
import { saveCommunicationShow } from '../react-redux/actions/studyAction';
import { Link } from 'react-router-dom';

class ChatBotsMember extends Component {
    completeLesson = () => {
        this.props.saveCommunicationShow(this.props.communication_id, this.props.user_id);
    }
    render() {
        const voices = speechSynthesis.getVoices();
        const japaneseVoice = voices.find(voice => voice.lang === 'ja-JP');
        return (
            <ChatBot
                headerTitle="Luyện shadowing nào"
                placeholder='Nhập câu trả lời'
                speechSynthesis={{ enable: true, lang: 'ja', voice: japaneseVoice }}
                recognitionEnable={true}
                recognitionLang="ja"
                recognitionPlaceholder='Nói câu trả lời'
                botDelay='2000'
                steps={[
                    {
                        id: 'sound',
                        component: (
                            <audio controls>
                                <source src={this.props.sound_shadowing} type="audio/mp3" />
                            </audio>
                        ),
                        trigger: 'note',
                    },
                    {
                        id: 'note',
                        component: (
                            <h5>Hãy nghe nhiều lần và luyện tập</h5>
                        ),
                        trigger: 'one_a',
                    },
                    {
                        id: 'one_a',
                        message: this.props.one_a,
                        trigger: 'one_b_write',
                    },
                    {
                        id: 'one_b_write',
                        user: true,
                        trigger: 'one_b',
                    },
                    {
                        id: 'one_b',
                        component: (
                            <div>Đáp án: {this.props.one_b}</div>
                        ),
                        trigger: 'two_a',
                    },
                    {
                        id: 'two_a',
                        message: this.props.two_a,
                        trigger: 'two_b_write',
                    },
                    {
                        id: 'two_b_write',
                        user: true,
                        trigger: 'two_b',
                    },
                    {
                        id: 'two_b',
                        component: (
                            <div>Đáp án: {this.props.two_b}</div>
                        ),
                        trigger: 'three_a',
                    },
                    {
                        id: 'three_a',
                        message: this.props.three_a,
                        trigger: 'three_b_write',
                    },
                    {
                        id: 'three_b_write',
                        user: true,
                        trigger: 'three_b',
                    },
                    {
                        id: 'three_b',
                        component: (
                            <div>Đáp án: {this.props.three_b}</div>
                        ),
                        trigger: 'four_a',
                    },
                    {
                        id: 'four_a',
                        message: this.props.four_a,
                        trigger: 'four_b_write',
                    },
                    {
                        id: 'four_b_write',
                        user: true,
                        trigger: 'four_b',
                    },
                    {
                        id: 'four_b',
                        component: (
                            <div>Đáp án: {this.props.four_b}</div>
                        ),
                        trigger: 'five_a',
                    },
                    {
                        id: 'five_a',
                        message: this.props.five_a,
                        trigger: 'five_b_write',
                    },
                    {
                        id: 'five_b_write',
                        user: true,
                        trigger: 'five_b',
                    },
                    {
                        id: 'five_b',
                        component: (
                            <div>Đáp án: {this.props.five_b}</div>
                        ),
                        trigger: 'mean',
                    },
                    {
                        id: 'mean',
                        component: (
                            <div>Nghĩa: {this.props.mean_shadowing}</div>
                        ),
                        trigger: 'notice'
                    },
                    {
                        id: 'notice',
                        component: (
                            <Link onClick={() => this.completeLesson()}><i className="fa-solid fa-square-check"></i>Đánh dấu bài học hoàn thành</Link>
                        ),
                        end: true,
                    },
                ]}
            />
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        study: state.study
    }
}
const mapDispatchToProps = {
    saveCommunicationShow
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatBotsMember);