import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';

class ReviewAllVocabGrammar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seriesV: [0,this.props.countVocabOne,this.props.countVocabTwo,this.props.countVocabThree,this.props.countVocabFour,this.props.countVocabFive],
            optionsV: {
                chart: {
                    height: 500,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        size: 100, // Điều chỉnh kích thước của radial bar
                        hollow: {
                            size: '55%', // Điều chỉnh kích thước của vòng tròn rỗng bên trong
                        },
                        dataLabels: {
                            name: {
                                fontSize: '22px',
                            },
                            value: {
                                fontSize: '16px',
                                color: '#777',
                                formatter: (val, opts) => {
                                    const seriesValue = this.state.seriesV[opts.seriesIndex];
                                    return seriesValue !== undefined ? `${seriesValue} từ` : `${val} từ`;
                                },
                            },
                            total: {
                                show: true,
                                label: 'Từ vựng ôn tập ' + 0,
                                formatter: function () {
                                    return `Số từ đã học: ${props.countVocabAll}`;
                                },
                                fontSize: '20px',
                                color: 'red'
                            },
                        },
                    },
                },
                labels: ['復習','一時','二つ目','三つ目','四つ目','五つ目'],
            },
            seriesG: [0,this.props.countGrammarOne,this.props.countGrammarTwo,this.props.countGrammarThree,this.props.countGrammarFour,this.props.countGrammarFive],
            optionsG: {
                chart: {
                    height: 500,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        size: 100, // Điều chỉnh kích thước của radial bar
                        hollow: {
                            size: '55%', // Điều chỉnh kích thước của vòng tròn rỗng bên trong
                        },
                        dataLabels: {
                            name: {
                                fontSize: '22px',
                            },
                            value: {
                                fontSize: '16px',
                                color: '#777',
                                formatter: (val, opts) => {
                                    const seriesValue = this.state.seriesG[opts.seriesIndex];
                                    return seriesValue !== undefined ? `${seriesValue} từ` : `${val} từ`;
                                },
                            },
                            total: {
                                show: true,
                                label: 'Ngữ pháp ôn tập ' + 0,
                                formatter: function () {
                                    return `Từ đã học: ${props.countGrammarAll}`;
                                },
                                fontSize: '20px',
                                color: 'red'
                            },
                        },
                    },
                },
                labels: ['復習','一時','二つ目','三つ目','四つ目','五つ目'],
            },
        };
    }
    showVocabReview = () => {
        return (
            <div className="vocabulary vocabReview">
                <div className="reviewVocab">
                    <ReactApexChart
                        options={this.state.optionsV}
                        series={this.state.seriesV}
                        type="radialBar"
                        height={500}
                    />
                </div>
                <div>
                    <Link to='/homeMember/reviewVocab' type="button" className="btn btn-primary">
                        Ôn tập từ vựng
                    </Link>
                </div>
            </div>
        )
    }
    showGrammarReview = () => {
        return (
            <div className="grammar grammarReview">
                <div className="reviewGrammar">
                    <ReactApexChart
                        options={this.state.optionsG}
                        series={this.state.seriesG}
                        type="radialBar"
                        height={500}
                    />
                </div>
                <div>
                    <Link to={'/homeMember/reviewGrammar'} type="button" className="btn btn-primary">
                        Ôn tập ngữ pháp
                    </Link>
                </div>
            </div>
        )
    }
    render() {
        return (
            <div className="reviewAllVocabGrammar">
                    {this.showVocabReview()}
                    {this.showGrammarReview()}
            </div>
        );
    }
}

export default ReviewAllVocabGrammar;